export const projects = [
  {
    title: 'Post College',
    projects: [
      {
        'title': 'Options Calculator',
        'links': [
          {
            title: 'Running Project',
            link: 'https://djd-options-dev.cfapps.io'
          },
          {
            title: 'Source Code',
            link: 'https://github.com/djdapz/options'
          },
        ],
        'blurb': 'Black-Scholes based options modeling tool.',
        'imgPath': 'gifs/stocks.gif'
      },
      {
        'title': 'Wine Inventory',
        'links': [
          {
            title: 'Source Code',
            link: 'https://github.com/djdapz/wine-inventory'
          },
        ],
        'blurb': 'A tool to track a wine cellar made for a family member.',
        'imgPath': 'gifs/wine.gif'
      },
      {
        'title': 'dapuzzo.dev',
        'links': [
          {
            title: 'Source Code',
            link: 'https://github.com/djdapz/personalWebsite'
          },
        ],
        'blurb': 'This website.',
        'imgPath': 'gifs/matrix.gif'
      },
      {
        'title': 'Luke Dapuzzo',
        'links': [
          {
            title: 'Running Project',
            link: 'http://www.lukedapuzzo.com'
          }
        ],
        'blurb': 'Band website for my brother. React + Spring',
        'imgPath': 'gifs/concert.gif'
      },
      {
        'title': 'Stubhub Scraper 2',
        'links': [
          {
            title: 'Source Code',
            link: 'https://github.com/djdapz/stubhub-scraper'
          }
        ],
        'blurb': 'Tested, Object Oriented version of my original scraper.',
        'imgPath': 'gifs/concert.gif'
      },
    ]
  },
  {
    title: 'Pre College',
    projects: [
      {
        'title': 'Parcheesi',
        'links': [
          {
            title: 'Source Code',
            link: 'https://github.com/djdapz/parcheesi'
          }
        ],
        'blurb': 'Java Implementation of Parcheesi - Example of OO Skills and Tests (before I learned TDD)',
        'imgPath': 'gifs/matrix.gif'
      },
      {
        'title': 'Dine',
        'links': [
          {
            title: 'Frontend Source Code',
            link: 'https://github.com/eecs394-f16/dine'
          },
          {
            title: 'Backend Source Code',
            link: 'https://github.com/eecs394-f16/dine_backend'
          }
        ],
        'blurb': 'App for independent travelers to find groups to dine with',
        'imgPath': 'gifs/dine.gif'
      },
      {
        'title': 'Clips',
        'links': [
          {
            title: 'Frontend Source Code',
            'link': 'https://github.com/eecs394-f16/clips',
          },
          {
            title: 'Backend Source Code',
            'link': 'https://github.com/eecs394-f16/eecs394-clips-backend',
          }
        ],
        'blurb': 'Application for saving coupons and deals at local businesses.',
        'imgPath': 'gifs/scrooge.gif'
      },
      {
        'title': 'Stubhub Ticket Scraper',
        'links': [
          {
            title: 'Source Code',
            'link': 'https://github.com/djdapz/eecs349_project',
          },
        ],
        'blurb': 'Automated Software used to collect data on ticket prices for a machine learning project.',
        'imgPath': 'gifs/concert.gif'
      },
      {
        'title': 'Auto DJ',
        'links': [
          {
            title: 'Presentation Website',
            'link': './autoDJ',
          },
        ],
        'blurb': 'Automatic DJ software project page for Music Processing course',
        'imgPath': 'gifs/dj_cat.gif'
      },
      {
        'title': 'Elimination Diet',
        'links': [
          {
            title: 'Mockup',
            'link': './eliminationDiet',
          },
        ],
        'blurb': 'iOS Application mockup for HCI class written in HTML/CSS/Javascript',
        'imgPath': 'gifs/diet2.gif'
      },
      {
        'title': 'Tripodium System',
        'links': [
          {
            title: 'Academic Poster',
            'link': './tripodium.pdf',
          },
        ],
        'blurb': 'Award winning product made in Design Thinking and Communication at NU.',
        'imgPath': 'gifs/engineering.gif'
      }
    ]
  }
]
